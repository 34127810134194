import {  useState } from 'react';
import '../style/entete.css';
import {Link} from "react-router-dom";
import { FaTimes } from "react-icons/fa";
const Entete = () => {

   const [mobile, setmobile] = useState(true);

   const shownavbar = () =>{
    if(mobile){
      setmobile(false);
    }
    else{
      setmobile(true);
    }
   }
  
    return ( 

        <div className=" navbar navbar-expand-lg navbar-dark bg-dark"  >
            <div class="container-fluid">
    <a class="navbar-brand " href="#">INNOSOFT</a>
   
    <div class=" Entete" >
      <ul class= {mobile? 'navbar-nav': 'nav-mobile'} >
        <li class="nav-item">
          <Link to="/" class="nav-link active a text-light" aria-current="page" href="#">Accueil</Link>
        </li>
        <li class="nav-item">
          <Link to="/Service" class="nav-link active a text-light" >Prestation de Services</Link>
        </li>
        <li class="nav-item">
          <Link to="/Formation" class="nav-link active a text-light" >Nos formations</Link>
        </li>
      </ul>
    <button  class="valide navbar-toggler" onClick={shownavbar} >
      {
        mobile? (<span class="navbar-toggler-icon"></span>):(<FaTimes/>)
      }
    </button>
    </div>
  </div>
</div>
     );
}
 
export default Entete;