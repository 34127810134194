const Jeune_Av
 = () => {
    return (  
       
        <div className="info">
             <div className="Container container-fluid jeune_Adv" >  <h1 className=""> ici c'est la formation pour les jeunes intermédiaire</h1></div>
        </div>
    );
}
 
export default Jeune_Av
;