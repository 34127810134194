import '../style/info_formation.css'
const Jeune
 = () => {
    return ( 
       
        <div className="info">
             <div className="Container container-fluid jeune_inter" >  <h1 className=""> ici c'est la formation pour les jeunes débutants</h1></div>
        </div>
     );
}
 
export default Jeune
;