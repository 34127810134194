import "./bootstrap-5.0.2-dist/css/bootstrap.min.css";
import './App.css';
import Accueil from "./component/Accueil";
import { BrowserRouter,Switch, Route} from "react-router-dom/cjs/react-router-dom.min";
import Services from "./component/Services";
import Formation from "./component/Formation";
import Jeune_Av from "./component/Jeune_Av";
import Jeune_Inter from "./component/Jeune_Inter";
import Adulte_Av from "./component/Adulte_Av";
import Adulte_Inter from "./component/Adulte_Inter"
import Notfound from "./component/Notfound";

function App() {
  return (
    <div className="App">
        <BrowserRouter> 
       <Switch>
  <Route path={'/'} exact> <Accueil/> </Route>
  <Route path={'/Service'} exact> <Services/>  </Route>
  <Route path={'/Formation'} exact> <Formation/>  </Route>
  <Route path={'/Formation/Jeune_Av'} exact> <Jeune_Av/>  </Route>
  <Route path={'/Formation/Jeune_Inter'} exact> <Jeune_Inter/>  </Route>
  <Route path={'/Formation/Adulte_Av'} exact> <Adulte_Av/> </Route>
  <Route path={'/Formation/Adulte_Inter'} exact> <Adulte_Inter/> </Route>
  <Route> <Notfound/></Route>
  </Switch>
  </BrowserRouter>
    </div>
  );
}

export default App;
